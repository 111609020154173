import { useContext } from 'react';
import { useParams } from "react-router-dom";
import { MainAppWrapper } from "../components/MainAppWrapperComponent.js";
import { setVariableIfNotNull, SearchConstants } from '../common.js';
import { AppContext } from "../context.js";

export function CategoryResultsPage() {
    const context = useContext(AppContext);
    let { category, subCategory } = useParams();

    let query = setVariableIfNotNull(category, context.navigation.default_keyword); // Set to category if not empty or null, otherwise default
    if (subCategory != null) {
        query += '_' + subCategory; // Set to sub category if not empty or null, otherwise keep same
    }

    return (
        <MainAppWrapper type={SearchConstants.category} query={query} />
    );
}
import './components.scss';
import { Children, cloneElement } from "react";
import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from "prop-types";
import { FilterEnums, propIsTrue, setVisibility, scrollElement } from '../common.js';
import { Links } from '../constants';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';

export function CircularButton({ ...props }) {
    return (
        <div className="ac_circularButton__root">
            <IconButton className="ac_circularButton__button" {...props}>
                {props.children}
            </IconButton>
        </div>
    );
}

export function WideWrapper({ ...props }) {
    const baseClass = 'components-wide-container__root';

    return (
        <div className={baseClass} {...props}>
            {props.children}
        </div>
    );
}

export function Wrapper({ removeTopPadding, ...props }) {
    const baseClass = 'components-container__root';
    const noPadding = baseClass + ' components-container__no-top-padding'

    return (
        <div className={removeTopPadding ? noPadding : baseClass} {...props}>
            {props.children}
        </div>
    );
}

export function FullPageModal({ ...props }) {
    return (
        <div className="components-full-page-modal__root">
            <div className="components-full-page-modal__container">
                {props.children}
            </div>
        </div>
    )
}

export function BottomSticker({ ...props }) {
    return (
        <div className="component-bottom-sticker__root" {...props}>
            {props.children}
        </div>
    );
}

export function VerticalScroller({ className, ...props }) {
    const ref = useRef(null);

    useEffect(() => {
        scrollElement(ref.current, 0, 0);
    });

    return (
        <div ref={ref} className={"component-scrollable__root " + className} {...props}>
            {props.children}
        </div>
    );
}

export function ImportantHeading({ ...props }) {
    const baseClass = 'components-important-heading__root';
    let additionalClasses = '';

    return (
        <h1 className={baseClass + additionalClasses} {...props}>
            {props.children}
        </h1>
    );
}

export function Heading({ centered, ...props }) {
    return (
        <h2 className={centered ? 'component-heading__root--centered' : 'component-heading__root'} {...props}>
            {props.children}
        </h2>
    );
}

export function Separator({ ...props }) {
    const hidden = props.hidden !== undefined ? props.hidden : props.hidden;

    return (
        <hr style={hidden === true ? { display: 'none' } : {}} className="component-separator__root" {...props} />
    );
}

export function CustomIconButton({ ...props }) {
    return (
        <IconButton style={{ padding: 0, margin: 0 }} {...props}>
            {props.children}
        </IconButton>
    )
}

export function LoadingIndicator({ ...props }) {
    return (
        <div className="loading-component__container">
            <div className="loading-component__indicator"></div>
            <div className="loading-component__text">{props.children}</div>
        </div>
    );
}

export function OutsideClickWrapper({ ...props }) {
    const ref = useRef(null);
    // Provide ref to props.ignore to ignore outside click action for a certain outside element
    // Function can be buggy if a proper ref hasn't been passed to props.ignore

    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && props.action !== undefined) {
            if (!props.ignore || (props.ignore.current && !props.ignore.current.contains(event.target))) { // Prevent from firing if the ignore element was clicked
                props.action();
            }
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const additionalClasses = props.className != undefined ? ' ' + props.className : '';

    return (
        <div ref={ref} className={"component-outsideclickwrapper__container" + additionalClasses}>
            {props.children}
        </div>
    )
}

export function ComponentScrollLock({ ...props }) {
    return (
        <div style={props.lock ? { overflow: 'hidden' } : { overflow: 'auto' }}>
            {props.children}
        </div>
    )
}

export function ScrollLock({ ...props }) {
    function lockScrolling(on) {
        if (on === true) {
            document.body.style.overflow = "hidden"; // Disable body scrolling
            document.body.style.touchAction = "none"; // Disable body scrolling
            document.body.style.position = "static"; // Disable body scrolling
            //document.getElementsByTagName('html')[0].style.overflow = "hidden"; // Disable body scrolling
        } else {
            document.body.style.overflow = "auto"; // Prevents bug where body gets locked when unmounting the component without exiting menu
            document.body.style.touchAction = "auto"; // Disable body scrolling
            document.body.style.position = ""; // Disable body scrolling
            //document.getElementsByTagName('html')[0].style.overflow = ""; // Disable body scrolling
        }
    }

    useEffect(() => {
        lockScrolling(props.lock);

        function cleanup() {
            lockScrolling(false); // Unlock scrolling on unmount
        }
    }, [props.lock]);

    return (
        <div style={{ display: 'none' }}></div>
    )
}

export function Overlay({ ...props }) {
    let containerClass = 'overlay-component__container';
    if (props.visible === false) {
        containerClass = containerClass + '--hidden';
    }

    return (
        <div className={containerClass}>
            <div className="overlay-component_content">
                {props.children}
            </div>
            <div onClick={props.close} className="overlay-component_empty-space"></div>
        </div>
    )
}

export function Fader({ ...props }) {
    const classes = makeStyles(() => ({
        root: {
            zIndex: 10000
        },
        wrapper: {
            width: '100%'
        }
    }));

    return (
        <div className={classes.wrapper}>
            <Fade className={classes.root} timeout={500} in={props.open}>
                <div>
                    {props.children}
                </div>
            </Fade>
        </div>
    );
}

export function Collapser({ ...props }) {
    const classes = makeStyles(() => ({
        root: {
            height: 180,
        },
        wrapper: {
            width: '100%',
        }
    }));

    return (
        <div className={classes.wrapper}>
            <Collapse in={props.open}>
                <div className="slider-component__invisible">
                    {props.children}
                </div>
            </Collapse>
        </div>
    );
}

export function NewSlider({ ...props }) {
    const ref = useRef(null);

    // Scroll to top of slider if it's scrollable
    useEffect(() => {
        if (props.open && props.open === true) {
            ref.current.scroll(0, 0);
        }
    }, [props.open]);

    return (
        <div ref={ref} in={props.open} mountOnEnter unmountOnExit>
            <div className="slider-component__invisible">
                {props.children}
            </div>
        </div>
    );
}

export function Slider({ ...props }) {
    const ref = useRef(null);

    // Scroll to top of slider if it's scrollable
    useEffect(() => {
        if (props.open && props.open === true) {
            ref.current.scroll(0, 0);
        }
    }, [props.open]);

    return (
        <Slide ref={ref} direction={props.direction} in={props.open} mountOnEnter unmountOnExit>
            <div className="slider-component__invisible">
                {props.children}
            </div>
        </Slide>
    );
}

export function Spacer({ ...props }) {
    return <div {...props} className="spacer-div"></div>
}

export function DesktopSpacer({ ...props }) {
    return <div className="spacer-div-desktop"></div>
}

export function DropdownButton({ ...props }) {
    const [isOpen, setOpen] = useState(false);

    // Give children the ability to close the dropdown
    const children = Children.map(props.children, (child) => {
        return cloneElement(child, { close: () => setOpen(false) })
    });

    return (
        <div {...props} className="dropdown__container">
            <OutsideClickWrapper action={() => setOpen(false)}>
                <Button className="dropdown__button component-button" onClick={() => setOpen(!isOpen)}>{props.text}</Button>
                <div className="dropdown__separator" style={setVisibility(isOpen)}> </div>
                <div className="dropdown__content" style={setVisibility(isOpen)}>{children}</div>
            </OutsideClickWrapper>
        </div>
    )

}

export function SinglePicker({ options, ...props }) {
    const [chosen, setChosen] = useState('');

    const elements = options.map(function (item, i) {
        const selected = item.name === props.selected ? true : false;
        return (
            <span key={i} className="single-picker__item-container">
                <Radio defaultChecked={selected} name="sort" value={item.name} radioTemplate={() => item.text} />
            </span>
        )
    });

    function updateParent(value) {
        if (props.update !== undefined) {
            props.update(value);
        }
    }

    function handleForm(e) {
        setChosen(e.target.value);
    }

    function reset(e) {
        for (var i = 0; i < e.target.length; i++) {
            e.target[i].checked = false; // Reset them
        }

        setChosen('');
        updateParent('');
    }

    function handleDone() {
        updateParent(chosen); // Send value to parent component
    }

    let backButton = null;
    let buttonWrapperClass = 'multi-picker__buttons--double';
    if (props.innerProps !== undefined && propIsTrue(props.innerProps.addBackButton)) {
        let handleClick = null;
        if (props.backAction) {
            handleClick = props.backAction;
        }

        backButton = <Button onClick={handleClick} className="component-button backButton">Tillbaka</Button>;;
        buttonWrapperClass = 'multi-picker__buttons--triple';
    }

    return (
        <>
            <Form onSubmit={e => reset(e)} onChange={handleForm} className="single-picker__form">
                {elements}

                <Spacer />

                <div className={buttonWrapperClass}>
                    <ResetButton buttonText="Återställ" />
                    {backButton}
                    <DoneButton onClick={handleDone} type="button" buttonText="Klar" />
                </div>
            </Form>
        </>
    )
}

// Choose multiple options
export function MultiPicker({ options, ...props }) {
    const [chosen, setChosen] = useState({});
    const [searchQuery, setSearchQuery] = useState('');

    // Update selected when props change
    useEffect(() => {
        var propValues = {}
        props.selected.forEach((el) => propValues[el] = true); // Convert to dictionary with key: true

        if (props.selected.length > 0) {
            setChosen({ ...chosen, ...propValues }); // Merge dictionaries
        }
    }, [props.selected]);

    function updateParent(values) {
        if (props.onSubmit) {
            props.onSubmit();
        }
        props.update(values);
    }

    function updateChosen(key, value) {
        setChosen({ ...chosen, [key]: value });
    }

    const defaultContainerClass = 'multi-picker__item-container';
    let containerClass = 'multi-picker__item-container';

    let itemWidthFull = true;
    if (props.innerProps !== undefined && props.innerProps.width !== undefined && props.innerProps.width === '50%') {
        containerClass = defaultContainerClass + '--half-width';
        itemWidthFull = false;
    }

    let elementsVisible = options.length;

    const elements = options.map(function (item, i) {
        let selected = false;

        if (props.selected !== undefined && props.selected.includes(item.name)) {
            selected = true;
        }

        let visible = true;
        if (searchQuery !== '' && !item.text.toLowerCase().includes(searchQuery.toLowerCase())) {
            visible = false;
            elementsVisible--;
        }

        // Set visibility to inline-block if checkboxes are half width
        let displayType = 'block';
        if (itemWidthFull === false) {
            displayType = 'inline-block';
        }

        return (
            <span style={setVisibility(visible, displayType = displayType)} key={i} className={containerClass}>
                <Checkbox defaultChecked={selected} name={item.name} checkboxTemplate={() => item.text} />
            </span>
        )
    });

    if (props.filterKey !== undefined && props.filterKey === FilterEnums.sale) {
        //console.log(selected);
    }

    function handleForm(e) {
        updateChosen(e.target.name, e.target.checked);
        // Do whatever action that is supposed to happen
        e.target.checked = e.target.checked;
    }

    function resetOptions(e) {
        for (var i = 0; i < e.target.length; i++) {
            e.target[i].checked = false; // Reset them
        }

        setChosen({});
        updateParent({});

        if (props.onReset !== undefined) {
            props.onReset();
        }
    }

    function onEscape(e) {
        setSearchQuery('');
        e.target.value = '';
    }

    let searchBar = null;
    if (props.innerProps !== undefined && propIsTrue(props.innerProps.search)) {
        let placeholder = 'Search';
        if (props.innerProps !== undefined && props.innerProps.searchText !== undefined) {
            placeholder = props.innerProps.searchText;
        }

        searchBar =
            <>
                <Input onChange={(e) => setSearchQuery(e.target.value)} onEscape={(e) => onEscape(e)} placeholder={placeholder} className="multi-picker__search" />
                <Spacer />
            </>
    }

    let noResultsText = 'No matches';
    if (props.innerProps !== undefined && props.innerProps.noResultsText !== undefined) {
        noResultsText = props.innerProps.noResultsText;
    }

    let noResults = <span>{noResultsText}</span>
    if (elementsVisible > 0) {
        noResults = null;
    }

    let resetButton = <ResetButton buttonText="Återställ" />
    let backButton = null;

    if (props.innerProps !== undefined && propIsTrue(props.innerProps.removeResetButton)) {
        resetButton = null;
    }

    let buttonWrapperClass = 'multi-picker__buttons--double';
    if (props.innerProps !== undefined && propIsTrue(props.innerProps.addBackButton)) {
        let handleClick = null;
        if (props.backAction) {
            handleClick = props.backAction;
        }

        backButton = <Button onClick={handleClick} className="component-button backButton">Tillbaka</Button>;;
        buttonWrapperClass = 'multi-picker__buttons--triple';
    }



    return (
        <>
            <OutsideClickWrapper>
                {searchBar}
                <Form onSubmit={e => resetOptions(e)} onChange={handleForm} className="multi-picker__form">
                    <div className="multi-picker__wrapper">
                        {elements}
                        {noResults}
                    </div>

                    <Spacer />

                    <div className={buttonWrapperClass}>
                        {resetButton}
                        {backButton}
                        <DoneButton onClick={() => updateParent(chosen)} type="button" buttonText="Klar" />
                    </div>
                </Form>
            </OutsideClickWrapper>
        </>
    )
}

export function DoneButton({ buttonText, ...props }) {
    if (buttonText == undefined) {
        buttonText = 'Done';
    }

    return (
        <Button className="multi-picker__done-button component-button--black" {...props}>{buttonText}</Button>
    )
}

export function ResetButton({ ...props }) {
    let buttonText = 'Reset';
    if (props.buttonText !== undefined) {
        buttonText = props.buttonText;
    }

    delete props.buttonText; // Don't pass it on

    return (
        <Button className="multi-picker__reset-button component-button" {...props}>{buttonText}</Button>
    )
}

export function ExpanderComponent(props) {
    const [isOpen, setOpen] = useState(props.open); // Keep track of open state
    const [data, setData] = useState(null); // Save data on open

    useEffect(() => {
        if (props.open === null) {
            setOpen(false);
        } else {
            setOpen(props.open);
        }
    }, [props.open])

    function handleClick() {
        setOpen(!isOpen);
        if (props.onClick) {
            props.onClick();
        }
    }

    function handleDataFetch() {
        props.loadData(setData);
    }

    // When it is open and we don't have any data
    useEffect(() => {
        if (isOpen === true && data === null && props.loadData) {
            handleDataFetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    var children = props.children;
    if (data !== null) {
        children = Children.map(children, (child) =>
            cloneElement(child, { data }) // Create child element and insert props
        )
    }

    return (
        <div>
            <Expander onClick={handleClick}>{props.expanderText}</Expander> {props.extraContent}
            <Expandable open={isOpen}>{children}</Expandable>
        </div>
    )
}

export function Expander(props) {
    return (
        <span style={{ cursor: 'pointer' }} onClick={props.onClick}>{props.children}</span>
    )
}

export function Expandable({ open, ...props }) {
    return (
        <div style={setVisibility(open)} {...props}>
            {props.children}
        </div>
    )
}

export function Button({ ...props }) {
    return (
        <button {...props}>{props.children}</button>
    )
}

export function Checkbox({ checkboxTemplate, ...props }) {
    return (
        <label><input type="checkbox" {...props}></input>{checkboxTemplate()}</label>
    )
}

export function Radio({ radioTemplate, ...props }) {
    return (
        <label><input type="radio" {...props}></input>{radioTemplate()}</label>
    )
}

export function Form({ ...props }) {
    const [values, setValues] = useState({});

    function handleChange(event) {
        const { name, value } = event.target;

        setValues(values => ({
            ...values,
            [name]: value
        }));

        if (props.onChange) {
            props.onChange(event);
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (props.onSubmit) {
            props.onSubmit(event, values);
        }
    }

    return (
        <form {...props} onChange={e => handleChange(e)} onSubmit={e => handleSubmit(e)}>
            {props.children}
        </form>
    )
}

export function Input({ onEnter, onEscape, ...props }) {
    const ref = useRef(null);
    const [value, setValue] = useState(null);
    const [focus, setFocus] = useState(props.autoFocus);

    function handleKeyDown(e) {
        if (e.key === 'Enter' && onEnter) {
            onEnter(e);
        } else if (e.key === 'Escape' && onEscape) {
            onEscape(e);
        }
    }

    function handleChange(e) {
        if (props.onChange) {
            props.onChange(e);
        } else {
            setValue(e.target.value);
        }
    }

    useEffect(() => {
        if (focus) {
            ref.current.focus();
            setFocus(false);
        }
    })

    return (
        <input {...props} ref={ref} onChange={(e) => handleChange(e)} onKeyDown={(e) => handleKeyDown(e)}></input>
    )
}

export function Select({ onChange, ...props }) {
    const [value, setValue] = useState(props.value);

    function handleChange(e) {
        setValue(e.target.value);
        onChange(e);
    }

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    return (
        <select {...props} value={value} onChange={(e) => handleChange(e)} />
    )
}

export function Textarea({ ...props }) {
    return (
        <textarea {...props}></textarea>
    )
}

export function PasswordInput({ ...props }) {
    return (
        <Input placeholder="Password" autoComplete="new-password" type="password" {...props} />
    )
}

export function ProgressBar({ value, ...props }) {
    return (
        <div>
            <progress value={value} max={props.max} />
            <span className="progress-text">{(value / props.max) * 100}%</span>
        </div>
    )
}

ProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
    buttonText: PropTypes.string
};

ProgressBar.defaultProps = {
    max: 100
};

MultiPicker.propTypes = {
    innerProps: PropTypes.shape({
        search: PropTypes.bool, // Enable search bar to filter choices (default is false)
        searchText: PropTypes.string, // Placeholder for search bar (IF search: true)
        noResultsText: PropTypes.string, // Text when no results (IF search: true)
        removeResetButton: PropTypes.bool, // Remove reset button of picker (default is false)
        width: PropTypes.string // Width of choices (50% or 100%, default is 100%)
    })
};

MultiPicker.defaultProps = {
    innerProps: {
        search: false,
        searchText: 'Search',
        noResultsText: 'No results',
        removeResetButton: false,
        width: '100%'
    }
};
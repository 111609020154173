import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CategoryResultsPage } from './pages/CategoryResultsPage';
import { SearchResultsPage } from './pages/SearchResultsPage';
import { MiscResultsPage } from './pages/MiscResultsPage';
import { CategoriesPage } from './pages/CategoriesPage';
import { BrandsPage } from './pages/BrandsPage';
import { ContactPage } from './pages/ContactPage';
import { AboutPage } from './pages/AboutPage';
import { Links, LinkParameter } from './constants';

// List of our routes, it is used to create routes for the app and the navigation. 
// If an object has the text attribute, it will be shown in navigation
// Order in list will determine order in navigation

export const allRoutes = {
    home: { url: '/', component: <CategoryResultsPage />, text: 'Hitta kläder' },
    categories: { url: Links.categories, component: <CategoriesPage />, hasChildren: true, text: 'Kategorier' },
    category: { url: Links.category + LinkParameter.category, component: <CategoryResultsPage />, hasChildren: true },
    subCategory: { url: Links.category + LinkParameter.subCategory, component: <CategoryResultsPage />, hasChildren: true },
    search: { url: Links.search + LinkParameter.search, component: <SearchResultsPage />, hasChildren: false },
    brands: { url: Links.brands, component: <BrandsPage />, hasChildren: true, text: 'Märken' },
    brand: { url: Links.brand + LinkParameter.brand, component: <MiscResultsPage />, hasChildren: true },
    contact: { url: Links.contact, component: <ContactPage />, hasChildren: false, text: 'Kontakt' },
    about: { url: Links.about, component: <AboutPage />, hasChildren: false, text: 'Om oss' }
}

export const navRoutes = [
    allRoutes.home,
    allRoutes.categories,
    allRoutes.brands,
    allRoutes.contact,
    allRoutes.about
]

export const pageRoutes = [
    allRoutes.search,
    allRoutes.categories,
    allRoutes.brands,
    allRoutes.brand,
    allRoutes.contact,
    allRoutes.about
]

export const sitemapRoutes = [
    allRoutes.categories,
    allRoutes.brands,
    allRoutes.contact,
    allRoutes.about,
    allRoutes.category,
    allRoutes.subCategory,
    allRoutes.brand
]

export function getRoutes(routeList) {
    if (!(routeList instanceof Array)) {
        routeList = Object.entries(routeList);
    }

    const routes = routeList.map((route, i) => {
        return (
            <Route key={i} exact path={route.url}>
                {route.component}
            </Route>
        )
    });

    return routes;
}

// Used when creating sitemap
export const SitemapRouter = (
    <Switch>
        {getRoutes(sitemapRoutes)}
    </Switch >
)
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { SortFilter, ColorFilter, PriceFilter, BrandFilter, StoreFilter, ResetFilters } from './FilterComponents';
import { setPageTitle, getUrlKeys, SearchConstants, useVisibility, StoreEnums } from '../common';
import { Links } from '../constants';
import { Wrapper, Heading, Separator, Slider, Spacer, DoneButton, FullPageModal, ImportantHeading, WideWrapper } from './BasicComponents';
import { ExpandableMenu } from './AdvancedComponents';
import { AutoCompleteSearch } from './AutoCompleteComponent';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import { FaTiktok, FaInstagram } from 'react-icons/fa';
import { AppContext } from '../context';

export const CategoryPage = withRouter(CategoryPageComponent);
export function CategoryPageComponent({ ...props }) {
    const context = useContext(AppContext);
    const items = context.navigation.sidebar_items;
    const [category, setCategory] = useState(null);
    const [subCategory, setSubCategory] = useState(null);
    const [page, setPage] = useState(0);

    const categoryParameter = props.match.params.category;
    const subCategoryParameter = props.match.params.subCategory;

    const data = useMemo(() => getSelectedCategories(), [categoryParameter, subCategoryParameter]);

    // Checks URL for which categories are selected
    function getSelectedCategories() {
        let selectedCategory = null;
        let selectedSubcategory = null;
        let selectedPage = 0;

        if (categoryParameter != null) {
            items.some(function (item, i) { // Iterate the categories
                if (item.url_key === categoryParameter) {
                    item.children.map(function (child, j) { // Iterate subcategories
                        if (subCategoryParameter == null) {
                            return true; // Don't loop in vain;
                        }

                        if (child.url_key === subCategoryParameter) {
                            selectedSubcategory = j;
                            return true;
                        }
                    });
                    selectedCategory = i;
                    return true;
                }
            });

            if (selectedCategory !== null) {
                selectedPage = 1;
            }
        }

        setCategory(selectedCategory);
        setSubCategory(selectedSubcategory);
        setPage(selectedPage);
    }

    function itemTemplate(item) {
        return (
            <Link to={Links.category + item.url_key}>
                {item.name}
                <span className="component-menu-drawer__item-arrow">
                    <MdKeyboardArrowRight />
                </span>
            </Link>
        );
    }

    function childTemplate(item, child) {
        return (
            <Link onClick={props.close} to={Links.category + item.url_key + '/' + child.url_key}>
                {child.name}
                <span className="component-menu-drawer__item-arrow">
                    <MdKeyboardArrowRight />
                </span>
            </Link>
        );
    }

    const itemOptions = {
        itemNameKey: 'name',
        itemTemplate: itemTemplate,
        childTemplate: childTemplate,
        firstHeading: () => { return 'Välj huvudkategori' },
        secondHeading: () => { return 'Välj underkategori' },
        firstIndex: category,
        secondIndex: subCategory,
        page: page
    }

    // key is used to reset it when the sub category changes
    return (
        <FullPageModal key={category + '-' + subCategory}>
            <ExpandableMenu items={items} itemOptions={itemOptions} />
        </FullPageModal>
    );
}

export function MinWidth({ ...props }) {
    return (
        <div className="component-min-width">
            {props.children}
        </div>
    )
}

export function HeightLimiter({ ...props }) {
    return (
        <div className="component-height-limiter">
            {props.children}
        </div>
    )
}

export function SliderMenuComponent({ items, ...props }) {
    const [value, setValue] = useState(null);
    const [firstOpen, setFirst] = useState(true);
    const [secondOpen, setSecond] = useState(false);
    const sliderTimeout = 250;

    let headingText = value === null ? props.innerProps.heading : items[value].name;

    function lockScrolling(value) {
        /*if (props.innerProps.scrollLock) {
            //props.innerProps.scrollLock(value);
        }*/
    }

    function showFirst() {
        setSecond(false);
        setTimeout(function () {
            setFirst(true);
        }, sliderTimeout);
    }

    function showSecond() {
        setFirst(false);
        setTimeout(function () {
            setSecond(true);
        }, sliderTimeout);
    }

    function handleItemClick(index) {
        setValue(index);
        showSecond();
    }

    function closeMenu() {
        if (props.innerProps.close) {
            props.innerProps.close();
        }
    }

    function handleBack() {

        showFirst();
        setValue(null);
    }

    function handleExit() {
        closeMenu();
    }

    function giveProp(component) {
        return React.cloneElement(component, { backAction: handleBack })
    }

    return (
        <Wrapper>
            <div className="component-menu-drawer__wrapper">
                <Heading onClick={handleExit}>{headingText}</Heading>
                <span className="component-menu-drawer__item-arrow">
                    <AiOutlineClose onClick={handleExit} />
                </span>
                <Separator />
                <Slider direction={'right'} open={firstOpen}>
                    <div className="component-menu-drawer__first-slide-container">
                        {items.map((item, i) => (
                            <div key={i} className={'component-menu-drawer__item' + (i === value ? '--selected' : '')}>
                                <Link onClick={() => handleItemClick(i)} to={'#' + item.url_key}>
                                    <span className={value === i ? 'bold-text' : ''}>{item.name}</span>
                                    <span className="component-menu-drawer__item-arrow">
                                        <MdKeyboardArrowRight />
                                    </span>
                                </Link>
                                <Separator hidden={i === items.length - 1} />
                            </div>
                        ))}
                    </div>
                    <div className="component-menu-drawer__children-container">
                        {props.children}
                    </div>
                </Slider>
                <Slider direction={'left'} open={secondOpen}>
                    {value != null ? giveProp(items[value].component) : null}

                    {/*
                    <div className="component-menu-drawer__back-button">
                        <DoneButton onClick={handleBack} buttonText="Tillbaka" />
                    </div>*/}
                </Slider>
            </div>
        </Wrapper>
    );
}

export function Page(props) {
    return (
        <div id="page-container">
            {props.children}
        </div>
    );
}

export function PageWithHeaderAndFooter(props) {
    return (
        <Page>
            {props.children}
        </Page>
    );
}

export function PageStaticContent({ ...props }) {
    return (
        <Page>
            <PageFiller>
                <WideWrapper>
                    {props.children}
                </WideWrapper>
            </PageFiller>
            <Footer />
        </Page>
    );
}

export function HeaderPadding(props) {
    return (
        <div className="header-padding">
            {props.children}
        </div>
    );
}

export function PagePadding(props) {
    return (
        <div className="page-padding">
            {props.children}
        </div>
    );
}

export function PageFiller(props) {
    return (
        <div className="page-filler">
            {props.children}
        </div>
    );
}

export function PageHeading({ stores, ...props }) {
    const context = useContext(AppContext);
    const url_keys = context.navigation.url_keys;

    let urlParameters = getUrlKeys();

    if (urlParameters.length === 0) {
        const storeString = stores.map(store => StoreEnums[store]).join(', ').replace(/, ([^,]*)$/, ' och $1');
        if (stores.length > 0) {
            return (
                <div id="welcomeText">
                    <Heading centered>Välkommen till Modelistan. Här hittar du kläder från {storeString}, på samma plats!</Heading>
                </div>
            );
        }
        return '';
    } else if (props.type === SearchConstants.search) {
        let pageTitle = decodeURI(urlParameters[1]);
        setPageTitle(pageTitle);

        return (
            <div id="searchHeading">
                <Heading centered>Sökresultat för</Heading>
                <ImportantHeading><Link to={Links.search + urlParameters[1]}>{pageTitle}</Link></ImportantHeading>
            </div>
        )
    } else if (props.type === SearchConstants.category) {
        let category = <Link to={Links.category + urlParameters[1]}>{url_keys[urlParameters[1]].name}</Link>
        let subCategory = '';
        let pageTitle = url_keys[urlParameters[1]].name;

        if (urlParameters.length > 2) {
            subCategory = <Link to={Links.category + urlParameters[1] + '/' + urlParameters[2]}>{url_keys[urlParameters[2]].name}</Link>
            pageTitle = url_keys[urlParameters[2]].name;
        }

        setPageTitle(pageTitle);

        return (
            <div id="breadcrumbs">
                <ImportantHeading>{subCategory !== '' ? subCategory : category}</ImportantHeading>
            </div>
        );
    } else if (props.type === SearchConstants.miscArticles) {
        const url_key = urlParameters[1];
        const brand = decodeURIComponent(urlParameters[1]);

        return (
            <div id="breadcrumbs">
                <Heading centered>Allt från</Heading>
                <ImportantHeading>{<Link to={Links.brand + url_key}>{brand}</Link>}</ImportantHeading>
            </div>
        );
    }

    return '';
}

export const CategorySlideMenu = withRouter(CategorySlideMenuComponent);

export function CategorySlideMenuComponent({ ...props }) {
    const context = useContext(AppContext);
    const items = context.navigation.sidebar_items;

    const [category, setCategory] = useState(null);
    const [subCategory, setSubCategory] = useState(null);
    const [page, setPage] = useState(0);
    const data = useMemo(() => getSelectedCategories(), [props.match.params.category, props.match.params.subCategory])

    // Checks URL for which categories are selected
    function getSelectedCategories() {
        let selectedCategory = null;
        let selectedSubcategory = null;
        let selectedPage = 0;

        const categoryParameter = props.match.params.category;
        const subCategoryParameter = props.match.params.subCategory;

        if (categoryParameter == '') {
            return; // Don't loop in vain;
        }

        items.map(function (item, i) { // Iterate the categories
            if (item.url_key === categoryParameter) {
                item.children.map(function (child, j) { // Iterate subcategories
                    if (subCategoryParameter == '') {
                        return; // Don't loop in vain;
                    }

                    if (child.url_key === subCategoryParameter) {
                        selectedSubcategory = j;
                        return;
                    }
                });
                selectedCategory = i;
                return;
            }
        });

        if (selectedCategory !== null) {
            selectedPage = 1;
        }

        setCategory(selectedCategory);
        setSubCategory(selectedSubcategory);
        setPage(selectedPage);
    }

    function lockScrolling(value) {
        if (props.innerProps.scrollLock) {
            props.innerProps.scrollLock(value);
        }
    }

    function closeMenu() {
        if (props.innerProps.close) {
            props.innerProps.close();
        }
    }

    function handleItemClick(index) {
        setCategory(index);
        lockScrolling(true);
        setPage(1);
    }

    function handleBack() {
        lockScrolling(false);

        setTimeout(function () {
            setCategory(null); // Show main menu again after slider duration
        }, 250);

        setSubCategory(null);
        setPage(0);
        //console.log('handle back');
    }

    function handleExit() {
        closeMenu();
        //console.log('handle exit');
    }

    function handleChildClick(index) {
        setSubCategory(index);
        closeMenu();
    }

    const categoryItem = items[category];
    const subCategoryItem = items[category].children[subCategory];

    return (
        <Wrapper>
            {items.map((item, i) => (
                <div key={i} className={'component-menu-drawer__item' + (i === category ? '--selected' : '')}>
                    <Link onClick={() => handleItemClick(i)} to={Links.category + item.url_key}>
                        <span className={item.url_key === props.match.params.category ? 'bold-text' : ''}>{item.name}</span>
                        <span className="component-menu-drawer__item-arrow">
                            <MdKeyboardArrowRight />
                        </span>
                    </Link>
                    <Separator hidden={i === items.length - 1} />
                </div>
            ))}
            <div className="component-menu-drawer__slider">
                <Wrapper>
                    <Slider direction={'left'} open={(page === 1)}>
                        <div className="component-menu-drawer__child-heading">
                            <span onClick={handleExit}>{categoryItem.name}</span>
                            <span className="component-menu-drawer__item-arrow">
                                <AiOutlineClose onClick={handleExit} />
                            </span>
                            <Separator />
                        </div>

                        {items[category === null ? 0 : category].children.map((child, j) => ( // Only render the currently selected one
                            <Link onClick={() => handleChildClick(j)} key={j} className="component-menu-drawer__child-container" to={Links.category + categoryItem.url_key + '/' + child.url_key}>
                                <div style={j === subCategory ? { fontWeight: 'bold' } : {}} className="component-menu-drawer__child">
                                    {child.name}
                                    <span className="component-menu-drawer__item-child-arrow">
                                        <MdKeyboardArrowRight />
                                    </span>
                                </div>
                                <Separator hidden={j === categoryItem.children.length - 1} />
                            </Link>
                        ))}
                        <div className="component-menu-drawer__back-button">
                            <DoneButton onClick={handleBack} buttonText="Tillbaka" />
                        </div>
                    </Slider>
                </Wrapper>
            </div>
        </Wrapper>
    );
}

export const FilterPage = withRouter(FilterPageComponent);
export function FilterPageComponent({ close, appProps, ...props }) {
    const colorProps = {
        width: '50%',
        addBackButton: false
    }

    const storeProps = {
        addBackButton: false
    }

    const brandProps = {
        search: true,
        searchText: 'Sök märken',
        noResultsText: 'Inga träffar',
        addBackButton: false
    }

    const priceProps = {
        removeResetButton: false,
        addBackButton: false
    }

    const sortProps = {
        addBackButton: false
    }

    let filterItems = [
        {
            name: 'Färg',
            url_key: 'color',
            childHeading: 'Välj färger',
            children: [<ColorFilter close={close} innerProps={colorProps} />]
        },
        {
            name: 'Butik',
            url_key: 'store',
            childHeading: 'Välj butiker',
            children: [<StoreFilter close={close} innerProps={storeProps} stores={appProps.stores} />]
        },
        {
            name: 'Märke',
            url_key: 'brand',
            childHeading: 'Välj märken',
            children: [<BrandFilter close={close} innerProps={brandProps} brands={appProps.brands} />]
        },
        {
            name: 'Sortering',
            url_key: 'sort',
            childHeading: 'Välj sortering',
            children: [<SortFilter close={close} innerProps={sortProps} />]
        },
        {
            name: 'Pris',
            url_key: 'price',
            childHeading: 'Välj prisklass',
            children: [<PriceFilter close={close} innerProps={priceProps} max={appProps.maxPrice} />]
        }
    ];

    const itemOptions = {
        itemNameKey: 'name',
        itemTemplate: (item) => { return item.name },
        childTemplate: (item, child) => { return child.component },
        firstHeading: (item) => { return 'Välj filter' },
        secondHeading: (item) => { return item.childHeading },
        hideBack: false,
        firstBottomButton: <ResetFilters reset={close} />
    }

    // Key is used to reset the component, reset whenever URL changes
    return (
        <FullPageModal key={props.location.key}>
            <div id="filter-page">
                <ExpandableMenu items={filterItems} itemOptions={itemOptions} />
            </div>
        </FullPageModal>
    );
}

export function SearchPage({ ...props }) {
    const ref = useRef();
    const isVisible = useVisibility(ref)

    useEffect(() => {
        if (isVisible) {
            ref.current.querySelector('input').focus(); // Focus the search field
        }
    }, [isVisible]);

    return (
        <div ref={ref} id="header-search">
            <AutoCompleteSearch />
        </div>
    );
}

export const LeftSidebar = withRouter(LeftSidebarComponent);

export function LeftSidebarComponent(props) {
    const context = useContext(AppContext);
    const [activeCategory, setActiveCategory] = useState(props.match.params.category);
    const [activeSubCategory, setActiveSubCategory] = useState(props.match.params.subCategory);

    useEffect(() => {
        setActiveCategory(props.match.params.category);
        setActiveSubCategory(props.match.params.subCategory);
    }, [props.match.params.category, props.match.params.subCategory]);

    var categories = context.navigation.sidebar_items;

    return (
        <div id="left-sidebar">
            <div className="category-container">
                <ImportantHeading>Kategorier</ImportantHeading>
                <Spacer />
                {categories.map((category, i) => (
                    <div key={i} className="category-item">
                        <Link className={activeCategory === category.url_key ? "active-category" : ''} to={Links.category + category.url_key}>{category.name}</Link>
                        <div className="category-item-children">
                            {category.children.map((child, j) => (
                                <Link key={j} className={activeSubCategory === child.url_key ? "active-category" : ''} to={Links.category + category.url_key + '/' + child.url_key}>
                                    <div className="category-item-child">{child.name}</div>
                                </Link>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <Spacer />
        </div>
    );
}

export function Footer({ padding, ...props }) {
    let style = {};
    if (!padding) {
        style.paddingBottom = 0;
    }

    return (
        <div style={style} className="footer__container">
            <div className="footer__content">
                &copy; Alla rättigheter reserverade
            </div>
        </div>
    );
}

export function Socials({ color, ...props }) {
    function SocialLink({ url, ...props }) {
        return (
            <a style={{ color: color }} href={url} target="_blank">
                {props.children}
            </a>
        )
    }

    return (
        <span className="components-social-medias__container">
            <SocialLink url="https://tiktok.com/@mode.listan/">
                <FaTiktok />
            </SocialLink>
            <SocialLink url="https://www.instagram.com/mode.listan">
                <FaInstagram />
            </SocialLink>
        </span>
    );
}
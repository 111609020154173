export const Links = {
    category: '/kategori/',
    search: '/sok/',
    categories: '/kategorier/',
    stores: '/butiker/',
    brands: '/marken/',
    brand: '/marke/',
    contact: '/kontakt/',
    about: '/om-oss/'
}

export const LinkParameter = {
    category: ':category',
    subCategory: ':category/:subCategory',
    brand: ':query',
    search: ':query',
}

export const LinkParameterSitemap = {
    category: LinkParameter.category.replace(':', ''),
    subCategory: LinkParameter.subCategory.split('/')[1].replace(':', '',),
    brand: LinkParameter.brand.replace(':', ''),
}
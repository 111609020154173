import { useParams } from "react-router-dom";
import { MainAppWrapper } from "../components/MainAppWrapperComponent.js";
import { SearchConstants } from "../common.js";

export function SearchResultsPage() {
    let { query } = useParams();

    return (
        <MainAppWrapper type={SearchConstants.search} query={query} />
    );
}
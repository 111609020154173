import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

export function useVisibility(ref) {
    // https://stackoverflow.com/a/65008608/4582696
    const [isIntersecting, setIntersecting] = useState(false);
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    );
  
    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect(); }
    }, []);
  
    return isIntersecting;
  }

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function setCookie(cname, cvalue, exdays = 365) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const encodedCname = encodeURI(cname);
    const encodedCvalue = encodeURI(cvalue);
    
    let expires = "expires=" + d.toUTCString();
    document.cookie = encodedCname + "=" + encodedCvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}

export function checkCookie(cname) {
    let cookie = getCookie(cname);
    if (cookie && cookie !== false) {
        return true
    }

    return false;
}

export function setVisibility(condition, displayType = 'block') {
    if (condition === true) {
        return { display: displayType };
    }

    return { display: 'none' };
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function setUrlParameters(history, parameter, value) {
    if (value !== null && value !== '') {
        let searchParams = new URLSearchParams(history.location.search);
        searchParams.set(parameter, value);
        history.push({ search: searchParams.toString() });
    } else {
        removeUrlParameter(history, parameter);
    }
}

export function removeUrlParameter(history, parameter) {
    let searchParams = new URLSearchParams(history.location.search);
    searchParams.delete(parameter);
    history.push({ search: searchParams.toString() });
}

export function getUrlKeys() {
    return window.location.pathname.split('/').filter(word => word.length > 0);
}

export function getUrlParameters() {
    let queryDict = {}
    window.location.search.substr(1).split("&").forEach(function (item) { queryDict[item.split("=")[0]] = item.split("=")[1] })
    return queryDict;
}

export function setPageTitle(pageTitle, custom = false) {
    let finalTitle = pageTitle + ' | Modelistan';
    if (custom === true) {
        finalTitle = pageTitle;
    }

    document.title = finalTitle;
}

export function setUrl(title, url) {
    window.history.pushState({}, title, url);
}

export function setUrlParametersOld(title, parameter, value) {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.set(parameter, value);

    setUrl(title, '?' + urlParams)
}

export function resetUrlParameters(history) {
    history.push({ search: '' });
}

export function resetUrlParametersOld(title) {
    setUrl(title, window.location.href.split('?')[0]);
}

export function removeParameter(title, key) {
    let parameters = getUrlParameters();
    if (parameters.hasOwnProperty(key)) {
        delete parameters[key];
    }

    let queryParameters = '?';
    for (let key in parameters) {
        if (key !== null) {
            queryParameters += key + '=' + parameters[key] + '&';
        }
    }

    queryParameters = queryParameters.slice(0, -1);

    setUrl(title, window.location.href.split('?')[0] + queryParameters);
}

export function scrollTop() {
    document.getElementsByTagName('body')[0].scrollIntoView({
        behavior: 'smooth'
    }, 500)
}

export function scrollElement(element, x, y) {
    element.scrollTo(x, y);
}
 
export function setVariableIfNotNull(variable, empty = '') {
    if (variable !== null && variable !== undefined && variable !== '') {
        return variable;
    }

    return empty;
}

export function notEmpty(variable, empty = '') {
    if (variable !== null && variable !== '') {
        return true;
    }

    return false;
}

export function propIsTrue(propValue) {
    if (propValue !== undefined && propValue === true) {
        return true;
    }

    return false;
}

export const StoreEnums = {
    asos: 'ASOS',
    nakd: 'NA-KD',
    zalando: 'Zalando',
    boohoo: 'boohoo',
    bubbleroom: 'Bubbleroom'
}

export function getStore(store) {
    if (StoreEnums[store] !== null) {
        return StoreEnums[store];
    }

    return store;
}

export const FilterEnums = {
    sort: 'sort',
    sale: 'sale',
    price: 'pricerange',
    color: 'color',
    store: 'store',
    brand: 'brand'
}

export * from './';

export const Strings = {
    search: 'search'
}

export const Endpoints = {
    search: '/api/search',
    navigation: '/api/navigation',
}

export const SearchConstants = {
    'search': 'search',
    'category': 'category',
    'miscData': 'misc_data',
    'miscArticles': 'misc_articles'
}
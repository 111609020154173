import { Endpoints } from './common.js';
import fetch from 'cross-fetch';

export async function searchProducts(searchData, callback = null) {
    const { query, type, filters } = searchData;

    if (query === null || query === '') {
        return;
    }

    postRequest(Endpoints.search, searchData, callback);
}

export async function postRequest(url, data, callback = null) {
    let requestUrl = process.env.REACT_APP_API_URL + url

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    };

    fetch(requestUrl, requestOptions)
        .then(response => response.json())
        .then(
            (result) => {
                if (callback != null) {
                    callback(result);
                }

                //console.log(result);

                return result;
            },
            (error) => {
                return error;
            }
        )
}

export async function getRequest(url, callback = null, onError = null) {
    let requestUrl = process.env.REACT_APP_API_URL + url

    const requestOptions = {
        method: 'GET',
    };

    fetch(requestUrl, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                if (callback != null) {
                    callback(result);
                }

                return result;
            },
            (error) => {
                if (onError != null) {
                    onError(error);
                }
                return error;
            }
        )
}
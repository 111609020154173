import { PageStaticContent } from '../components/PageComponents';
import { setPageTitle } from '../common';
import { ImportantHeading, Spacer } from "../components/BasicComponents";
import { ContactForm } from "../components/AdvancedComponents";

export function ContactPage() {
    setPageTitle('Kontakt');

    return (
        <PageStaticContent>
            <ImportantHeading>Kontakt</ImportantHeading>
            <Spacer />
            <div className="contact-page__container">
                <p>Hör av dig! Vi uppskattar att du använder vår tjänst och vill gärna höra vad du tycker. Skicka in dina önskemål, frågor eller vad som helst.</p>
                <p>Använd kontakformuläret nedan eller maila oss på <a style={{textDecoration:'underline'}} href="mailto:info@modelistan.se">info@modelistan.se</a>.</p>
                <ContactForm />
            </div>
        </PageStaticContent>
    );
}
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom'
import { Slider } from '@material-ui/core/';
import { notEmpty, useQuery, setVariableIfNotNull, FilterEnums, removeUrlParameter, resetUrlParameters, setUrlParameters, getStore, propIsTrue } from '../common.js'
import { Wrapper } from './PageComponents';
import { DropdownButton, MultiPicker, SinglePicker, ResetButton, Spacer } from './BasicComponents';
import { AppContext } from '../context';

function MultiOptionFilter({ filterKey, ...props }) {
    let history = useHistory();
    let selected = [];
    const query = setVariableIfNotNull(useQuery().get(filterKey));

    // Get values from URL query
    if (query !== '') {
        selected = query.split(',');
    }

    // Remove URL query for component when it reaches end of lifecycle
    useEffect(() => {
        return function cleanup() {
            //setUrlParameters(history, filterKey, '');
            //console.log('FIRE')
            //console.log(filterKey)
        };
    }, []);

    // Converts values from dict to array and adds to URL
    function updateUrl(values) {
        let valueArray = []

        for (var key in values) {
            if (values.hasOwnProperty(key) && values[key] === true) {
                valueArray.push(key);
            }
        }

        setUrlParameters(history, filterKey, valueArray.join());

        if (props.close !== undefined) {
            props.close();
        }
    }

    return (
        <MultiPicker filterKey={filterKey} selected={selected} update={values => updateUrl(values)} options={props.options} {...props}></MultiPicker>
    );
}

function SingleOptionFilter({ filterKey, ...props }) {
    let history = useHistory();
    const selected = setVariableIfNotNull(useQuery().get(filterKey));

    // Remove URL query for component when it reaches end of lifecycle
    useEffect(() => {
        return function cleanup() {
            //setUrlParameters(history, filterKey, '');
        };
    }, []);

    function updateUrl(value) {
        setUrlParameters(history, filterKey, value);

        if (props.close !== undefined) {
            props.close();
        }
    }

    return (
        <SinglePicker selected={selected} update={value => updateUrl(value)} options={props.options} {...props}></SinglePicker>
    );
}

// Documentation for slider: https://material-ui.com/api/slider/
export function PriceFilter({ ...props }) {
    //console.log(filter)
    const [currentMin, setMin] = useState(0); // Current min value
    const [currentMax, setMax] = useState(props.max); // Current max value

    const filterValue = setVariableIfNotNull(useQuery().get(FilterEnums.price));

    useEffect(() => {
        // Set initial values
        let min = 0;
        let max = props.max;
        
        if (filterValue) {
            let filter = filterValue.split('-');
            min = filter[0];
            max = filter[1];
        }

        setMin(min);
        setMax(max);
    }, [props.max]);

    function displayCurrentMax() {
        // If something went wrong
        if (props.max === null) {
            return '0';
        }

        if (currentMax === 0) {
            return props.max;
        } else {
            return currentMax;
        }
    }

    function onChange(e, v) {
        let start = v[0];
        let end = v[1];

        if (start === null) {
            start = 0
        }

        if (end === null) {
            end = props.max;
        }

        setMin(start);
        setMax(end);
    }

    function commit(e, v, update = false) {
        let start = v[0];
        let end = v[1];

        setMin(start);
        setMax(end);

        // We don't always wanna update the price filter
        if (update) {
            updateUrl(start, end);
        }
    }

    let history = useHistory();

    // Update query params
    function updateUrl(start = currentMin, end = currentMax) {
        if (start > 0 || end < props.max) {
            setUrlParameters(history, FilterEnums.price, start + '-' + end);
        } else {
            removeUrlParameter(history, FilterEnums.price);
        }
    }

    function reset() {
        commit(null, [0, props.max], true);
    }

    return (
        <div>
            <div className="price-filter-indicator price-min"><span className="price-filter-amount">{currentMin}</span> SEK</div>
            <div className="price-filter-indicator price-max"><span className="price-filter-amount">{displayCurrentMax()}</span> SEK</div>
            <div className="price-filter-slider-wrapper">
                <Slider
                    step={1}
                    value={[Number(currentMin), Number(currentMax)]}
                    min={0}
                    max={props.max}
                    onChange={(e, v) => onChange(e, v)}
                    onChangeCommitted={(e, v) => commit(e, v)}
                    valueLabelDisplay={'off'}
                    classes={{
                        root: 'slider-root',
                        track: 'slider-track',
                        thumb: 'slider-handle',
                        rail: 'slider-rail'
                    }}
                />
            </div>
            <Spacer />
            <SaleFilter onSubmit={updateUrl} onReset={reset} {...props} />
        </div>
    );
}

export function ColorFilter({ ...props }) {
    const options = [
        { name: 'black', text: 'Svart' },
        { name: 'white', text: 'Vit' },
        { name: 'beige', text: 'Beige' },
        { name: 'pink', text: 'Rosa' },
        { name: 'blue', text: 'Blå' },
        { name: 'green', text: 'Grön' },
        { name: 'purple', text: 'Lila' },
        { name: 'orange', text: 'Orange' },
        /*{name:'multicolor', text: 'Flerfärgad'},*/
        { name: 'yellow', text: 'Gul' },
        { name: 'gray', text: 'Grå' },
        { name: 'red', text: 'Röd' },
        { name: 'brown', text: 'Brun' },
        { name: 'flower', text: 'Blommig' },
        { name: 'animal', text: 'Djurmönster' }
    ];

    return <MultiOptionFilter classes="color-filter button" filterKey={FilterEnums.color} options={options} {...props} />
}

export function StoreFilter({ ...props }) {
    const options = props.stores.map(function (item) {
        return { name: item, text: getStore(item) };
    });

    return <MultiOptionFilter classes="store-filter button" filterKey={FilterEnums.store} options={options} {...props} />
}

export function BrandFilter({ ...props }) {
    const options = props.brands.map(function (item) {
        return { name: item, text: item };
    });

    return <MultiOptionFilter classes="brand-filter button" filterKey={FilterEnums.brand} options={options} {...props} />
}

export function SortFilter({ ...props }) {
    const options = [
        { name: 'low-high', text: 'Lågt till högt pris' },
        { name: 'high-low', text: 'Högt till lågt pris' },
    ];

    return <SingleOptionFilter classes="sort-filter button" filterKey={FilterEnums.sort} options={options} {...props} />
}

export function SaleFilter({ ...props }) {
    const options = [
        { name: 'sale-only', text: 'Endast rea' },
    ];

    return <MultiOptionFilter classes="sale-filter button" filterKey={FilterEnums.sale} options={options} {...props} />
}

export function FilterWrapperDesktop({ appProps, ...props }) {
    const { stores, brands, maxPrice } = appProps;

    const colorProps = {
        width: '50%'
    }

    const storeProps = {
        noResultsText: 'Inga träffar',
        width: '50%'
    }

    const brandProps = {
        search: true,
        searchText: 'Sök märken',
        noResultsText: 'Inga träffar'
    }

    const priceProps = {
        removeResetButton: false
    }

    return (
        <div className="filter-wrapper__container--desktop desktop-only">
            <Spacer />
            <DropdownButton text="Färger">
                <ColorFilter innerProps={colorProps} />
            </DropdownButton>
            <DropdownButton text="Butiker">
                <StoreFilter stores={stores} innerProps={storeProps} />
            </DropdownButton>
            <DropdownButton text="Märken">
                <BrandFilter brands={brands} innerProps={brandProps} />
            </DropdownButton>
            <DropdownButton text="Pris">
                <PriceFilter max={maxPrice} innerProps={priceProps} />
            </DropdownButton>
            <DropdownButton id="filter-wrapper__last-button" text="Sortering">
                <SortFilter />
            </DropdownButton>
            <ResetFilters />
        </div>
    )
}

export function ResetFilters({ ...props }) {
    let history = useHistory();

    function reset() {
        resetUrlParameters(history);
        if (props.reset) {
            props.reset();
        }
    }

    return (
        <button onClick={reset} className="component-button--black filter-reset">
            Återställ allt
        </button>
    );
}

PriceFilter.propTypes = {
    max: PropTypes.number.isRequired
}

StoreFilter.propTypes = {
    stores: PropTypes.array.isRequired
}

BrandFilter.propTypes = {
    brands: PropTypes.array.isRequired
}
import React from 'react';
import { useState, useEffect } from 'react';
import './index.scss';
import { Header } from './components/HeaderComponent.js';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getRoutes, pageRoutes } from './routes';
import { FavoriteHelper } from './components/FavoriteComponents';
import { Endpoints } from './common';
import { AppContext } from './context.js';
import { getRequest } from './fetchers';
import { CategoryResultsPage } from './pages/CategoryResultsPage';

export function App() {
    const [contextVal, setContext] = useState(0);
    const [helper] = useState(() => new FavoriteHelper());
    const [showHeader, setHeaderVisibility] = useState(false); // Used to slide in header when it's gone
    const [navigationData, setNavigationData] = useState();

    useEffect(() => {
        getRequest(Endpoints.navigation, setNavigationData);
    }, [])

    // Used by entire app
    const contextProps = {
        navigation: navigationData,
        favorites: helper,
        favoriteCount: contextVal,
        update: () => setContext(contextVal + 1),
        showHeader: setHeaderVisibility,
        close: null,
    };

    // Wait until we get an answer
    if (navigationData == null) {
        return (<div></div>);
    }

    // First route contains the same component, so they go under the same route to prevent unnecessary rerenders
    return (
        <>
            <AppContext.Provider value={contextProps}>
                <Router>
                    <Header visible={showHeader} />
                    <Switch>
                        <Route exact path={["/kategori/:category/:subCategory","/kategori/:category","/"]}>
                            <CategoryResultsPage />
                        </Route>
                        {getRoutes(pageRoutes)}
                    </Switch>
                </Router>
            </AppContext.Provider>
        </>
    );
}
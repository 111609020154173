import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageStaticContent } from '../components/PageComponents';
import { setPageTitle, Endpoints, SearchConstants } from '../common';
import { Links } from '../constants';
import { postRequest } from '../fetchers';
import { ImportantHeading, Heading, Spacer } from "../components/BasicComponents";
import { BackToTop, BackToTopAnchor } from "../components/AdvancedComponents";

export function CategoriesPage() {
    setPageTitle('Kategorier');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        postRequest(Endpoints.search, { 'query': 'categories', 'type': SearchConstants.miscData }, (result) => setCategories(result));
    }, [])

    const baseClass = 'categories-page__';

    const sortedCategories = categories.sort((a, b) => a.name.localeCompare(b.name));

    const categoryElements = sortedCategories.map((item, i) => {
        return (
            <div className={baseClass + 'category-container'} key={i}>
                <Link to={Links.category + item.url_key}><Heading>{item.name}</Heading></Link>
                <Spacer />
                <div className={baseClass + 'sub-category-container'}>
                    {
                        item.children.map((child, j) => {
                            return (
                                <Link className={baseClass + 'sub-category-item'} to={Links.category + item.url_key + '/' + child.url_key} key={j}>{child.name}</Link>
                            )
                        })
                    }
                </div>
            </div>
        )
    })

    return (
        <PageStaticContent>
            <BackToTopAnchor />
            <ImportantHeading>Kategorier</ImportantHeading>
            {categoryElements}
            <BackToTop />
        </PageStaticContent>
    );
}
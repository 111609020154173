import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  Highlight,
} from 'react-instantsearch-dom';
import Autocomplete from './autocomplete/Autocomplete';
import './autocomplete/Autocomplete.scss';
import { useHistory } from "react-router-dom";
import { Links } from '../constants';

// https://codesandbox.io/s/github/algolia/doc-code-samples/tree/master/React+InstantSearch/autocomplete-results-page?file=/src/App.js
// Custom search client so we make calls to our backend instead of Algolia https://github.com/algolia/react-instantsearch/issues/2847

export function AutoCompleteSearch(props) {
  const [query, setQuery] = useState('');
  const history = useHistory();

  const cache = new Map();
  const promiseCache = new Map();

  const doRequest = body =>
    fetch(process.env.REACT_APP_API_URL + '/api/auto_complete', {
      method: 'post',
      body,
      headers: {
        'Content-Type': 'application/json',
      }
    }).then(res =>
      res.json()
    );

  const customSearchClient = {
    async search(requests) {
      const body = JSON.stringify({ requests });

      const cached = cache.get(body);

      if (cached) {
        return cached;
      }

      const promiseCached = promiseCache.get(body);

      if (promiseCached) {
        return promiseCached;
      }

      const query = requests[0].params.query;
      //console.log(query);

      if (query != null && query.length > 0) {
        const promise = doRequest;
        promiseCache.set(body, promise);
        const response = await promise(body);
        cache.set(body, response);
        promiseCache.delete(body);
        return response;
      }

      return null;
    }
  };

  function onSuggestionSelected(e, { suggestion }) {
    //console.log(e);
    if (suggestion.query.length > 0) { // Prevent when query is empty
      setQuery(suggestion.query);
      const query = encodeURIComponent(suggestion.query); // Prevent funny characters in URL

      history.push(Links.search + query);
    }
  };

  function onSuggestionCleared() {
    setQuery('');
  };

  return (
    <InstantSearch indexName="auto_complete_query_suggestions" searchClient={customSearchClient}>
      <Configure hitsPerPage={10} />
      <Autocomplete
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionCleared={onSuggestionCleared}
        minLength={3}
      />
    </InstantSearch>
  );
}

function Hit(props) {
  return (
    <div>
      <Highlight attribute="query" hit={props.hit} />
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

import React, { useState, useRef, useEffect, useContext } from 'react';
import { HeightLimiter, MinWidth } from './PageComponents';
import { ProductWrapper, FavoriteProduct } from './ProductComponents';
import { getCookie, setCookie } from '../common';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { FaHeart } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import { Wrapper, OutsideClickWrapper, CustomIconButton, Button, Spacer, Heading, CircularButton } from './BasicComponents';
import IconButton from '@material-ui/core/IconButton';
import { AppContext } from '../context';

const redHeartColor = '#fb5734';

export function FavoriteHelper() {
    const obj = {};
    const countString = 'favoritesCount';
    const storeString = 'favoritesStore';
    const storage = window.localStorage;

    obj.checkMarked = function (id) {
        const list = obj.getDictionary();
        return list[id] != undefined ? true : false
    }

    obj.getCount = function () {
        let count = obj.getArray().length;

        if (count === false) {
            return 0;
        } else if (Number.isInteger(parseInt(count))) {
            return parseInt(count);
        }

        return 0;
    }

    obj.getDictionary = function () {
        let items = storage.getItem(storeString);
        if (items !== null) {
            items = JSON.parse(items);
            return items;
        }

        return {};
    }

    obj.getArray = function () {
        let dict = obj.getDictionary();
        //console.log(dict);
        let array = Object.values(dict);
        //console.log(array);
        return array;
    }

    obj.setItems = function (items) {
        //console.log(items)
        storage.setItem(storeString, JSON.stringify(items));
        //setCookie(storeString, JSON.stringify(items));
    }

    obj.add = function (item) {
        let items = obj.getDictionary();
        items[item.id] = item
        obj.setItems(items);
    }

    obj.remove = function (item) {
        let items = obj.getDictionary();
        delete items[item.id];
        obj.setItems(items);
    }

    obj.clearFavorites = function () {
        obj.setItems({});
    }

    return obj;
}

export function FavoriteButton({ item, ...props }) {
    const context = useContext(AppContext);
    const [selected, setSelected] = useState(context.favorites.checkMarked(item.id));

    useEffect(() => {
        setSelected(context.favorites.checkMarked(item.id));
    }, [context.favoriteCount])

    function handleClick() {
        setSelected(!selected);

        context.update();

        if (selected) {
            context.favorites.remove(item);
        } else {
            context.favorites.add(item);
        }
    }

    let style = selected === true ? { color: redHeartColor } : {};

    return (
        <>
            <CircularButton onClick={handleClick} color="inherit" aria-label="favorite button">
                <FaHeart style={style} />
            </CircularButton>
        </>
    )
}

export function Favorites({ ...props }) {
    const [open, setOpen] = useState(false);
    const ignoreRef = useRef(null);
    const context = useContext(AppContext);

    function clearFavorites() {
        context.favorites.clearFavorites();
        context.update();
    }

    const favoritesCount = context.favorites.getCount();

    if (favoritesCount > 0) {
        var elements = context.favorites.getArray().map(function (item, i) {
            return <ProductWrapper item={item} key={i}><FavoriteProduct /></ProductWrapper>
        });
    } else {
        var elements = <Heading>Inga favoriter valda.</Heading>
    }

    const heartStyle = context.favorites.getCount() > 0 ? { color: redHeartColor } : {};

    return (
        <>
            <IconButton ref={ignoreRef} onClick={() => setOpen(!open)} edge="end" color="inherit" aria-label="favorites">
                <FaHeart style={heartStyle} />
            </IconButton>
            <Drawer anchor={'right'} hideBackdrop={false} open={open} onClose={() => setOpen(false)}>
                <HeightLimiter>
                    <OutsideClickWrapper ignore={ignoreRef} action={() => setOpen(!open)}>
                        <Wrapper>
                            <MinWidth>
                                <div className="favorites-flex-container">
                                    <Toolbar variant={'dense'} disableGutters={true}>
                                        <Typography style={{ flex: 1 }} variant="h6">Favoriter</Typography>
                                        <CustomIconButton onClick={() => setOpen(false)} edge="end" color="inherit" aria-label="favorites">
                                            <AiOutlineClose />
                                        </CustomIconButton>
                                    </Toolbar>

                                    <Button className="favorites__remove component-button" onClick={clearFavorites}>Rensa favoriter</Button>

                                    <Spacer style={{margin: '5px 0'}} />

                                    <div className="favorites__container">
                                        {elements}
                                    </div>
                                </div>
                            </MinWidth>
                        </Wrapper>
                    </OutsideClickWrapper>
                </HeightLimiter>
            </Drawer>
        </>
    )
}
import React, { useState, useRef } from 'react';
import { PageFiller, MinWidth, Socials } from './PageComponents';
import { Wrapper, OutsideClickWrapper } from './BasicComponents';
import { Favorites } from './FavoriteComponents';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Drawer, List, ListItem } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { navRoutes } from '../routes';
import { setPageTitle } from '../common';

export function Header({ visible, ...props }) {
    const [open, setOpen] = useState(false);
    let ignoreRef = useRef(null);

    let trigger = !useScrollTrigger();

    if (visible === true) {
        trigger = true;
    }

    return (
        <Slide direction="down" in={trigger}>
            <AppBar className="header-container" position="fixed">
                <Wrapper removeTopPadding>
                    <Toolbar variant={'dense'} disableGutters={true} style={{ padding: 0 }}>
                        <IconButton ref={ignoreRef} onClick={() => setOpen(!open)} edge="start" color="inherit" aria-label="menu">
                            <MdMenu />
                        </IconButton>
                        <Typography variant="h6"><Link onClick={() => setPageTitle('Modelistan', true)} className="header-branding special-font" to="/">Modelistan</Link></Typography>
                        <div style={{ marginLeft: 'auto' }}>
                            <Favorites />
                        </div>
                    </Toolbar>
                </Wrapper>
                <Drawer anchor={'left'} open={open}>
                    <Wrapper>
                        <OutsideClickWrapper ignore={ignoreRef} action={() => setOpen(!open)}>
                            <PageFiller>
                                <MinWidth>
                                    <Toolbar variant={'dense'} disableGutters={true}>
                                        <Typography style={{ flex: 1 }} variant="h6">Meny</Typography>
                                        <IconButton onClick={() => setOpen(false)} edge="end" color="inherit" aria-label="menu">
                                            <AiOutlineClose />
                                        </IconButton>
                                    </Toolbar>
                                    <List disablePadding={true} dense={true}>
                                        {
                                            navRoutes.map((route, i) => {
                                                const isActive = window.location.pathname === route.url;
                                                if (route.text != undefined) {
                                                    return <Link className={isActive ? 'bold-text' : ''} key={i} to={route.url} onClick={() => setOpen(!open)}><ListItem button={true} disableGutters={true}>{route.text}</ListItem></Link>
                                                }
                                            })
                                        }
                                    </List>
                                    
                                    <Toolbar variant={'dense'} disableGutters={true} style={{ padding: 0 }}>
                                        <Typography variant="subtitle1"><strong>Sociala medier</strong></Typography>
                                    </Toolbar>
                                    <Socials />
                                </MinWidth>
                            </PageFiller>
                        </OutsideClickWrapper>
                    </Wrapper>
                </Drawer>
            </AppBar>
        </Slide>
    );
}
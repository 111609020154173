import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Page, LeftSidebar, PageHeading, PageFiller } from './PageComponents.js';
import { Wrapper, Spacer, DesktopSpacer } from './BasicComponents';
import { ProductContainer } from './ProductComponents.js';
import { FilterWrapperDesktop } from './FilterComponents.js';
import { AutoCompleteSearch } from './AutoCompleteComponent';
import { searchProducts } from '../fetchers.js';
import { AppContext } from '../context.js';
import { setVariableIfNotNull, FilterEnums, useQuery, scrollTop } from '../common.js';
import { Footer } from './PageComponents.js';
import { FooterButtons } from './FooterButtonsComponents';

export const MainAppWrapper = withRouter(MainAppWrapperComponent);

function MainAppWrapperComponent({ ...props }) {
    const context = useContext(AppContext);
    const [isLoading, setLoading] = useState(true);
    const [items, setItems] = useState(null); // Must be null to let program know it's initializing
    const [stores, setStores] = useState([]);
    const [maxPrice, setMaxPrice] = useState(1);
    const [brands, setBrands] = useState([]);

    const appProps = {stores: stores, brands: brands, maxPrice: maxPrice};

    let queryParameters = useQuery();

    let priceRange = setVariableIfNotNull(queryParameters.get(FilterEnums.price));
    let colorFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.color));
    let sortFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.sort));
    let saleFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.sale));
    let storeFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.store));
    let brandFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.brand));

    function handleItemsChange(response) {
        // When shit goes wrong
        if (response.items === null || response.items.length === 0) {
            setItems([]);
            setMaxPrice(1);
            setStores([]);
            setBrands([]);
            setLoading(false);
            return;
        }

        setItems(response.items);
        //setItems([]);

        if (maxPrice === 1) { // Prevents max price being lowered while filtering
            setMaxPrice(response.max_price);
        }

        setStores(response.stores);

        if (brands.length === 0) { // Prevents losing brands while filtering
            setBrands(response.brands);
        }

        setLoading(false);
    }

    // When we change filters
    useEffect(() => {
        setLoading(true);
        //console.log(props.match);

        scrollTop();

        priceRange = setVariableIfNotNull(queryParameters.get(FilterEnums.price));
        colorFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.color));
        sortFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.sort));
        saleFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.sale));
        storeFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.store));
        brandFilter = setVariableIfNotNull(queryParameters.get(FilterEnums.brand));

        const searchData = {
            query: props.query,
            type: props.type,
            filters: {
                price: priceRange,
                color: colorFilter,
                sort: sortFilter,
                sale: saleFilter,
                store: storeFilter,
                brand: brandFilter
            }
        }

        searchProducts(searchData, handleItemsChange);
    }, [props.location.search, props.query]);

    // While site is initializing
    if (context.navigation == null) {
        return <div>Laddar...</div>;
    }

    return (
        <Page>
            <PageFiller>
                <Wrapper removeTopPadding>
                    <PageHeading stores={stores} type={props.type} />

                    <div className="search-container">
                        <div className="searchbox-container">
                            <AutoCompleteSearch />
                        </div>

                        <DesktopSpacer />

                        <FilterWrapperDesktop appProps={appProps} key={props.location.key} />

                        <LeftSidebar />

                        <DesktopSpacer />

                        <ProductContainer isLoading={isLoading} items={items} />
                    </div>
                </Wrapper>
            </PageFiller>
            <FooterButtons appProps={appProps} />
            <Footer padding />
        </Page>
    );
}
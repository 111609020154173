import { Link } from "react-router-dom";
import { PageStaticContent } from '../components/PageComponents';
import { setPageTitle } from '../common';
import { Links } from '../constants';
import { ImportantHeading, Spacer } from "../components/BasicComponents";

export function AboutPage() {
    setPageTitle('Om oss');

    return (
        <PageStaticContent>
            <ImportantHeading>Om oss</ImportantHeading>
            <Spacer />
            <p>
                Vi erbjuder en tjänst där vi samlar kläder och tillbehör från dina favoritsidor så du enkelt kan hitta det du söker på en och samma plats.
                <br />
                <br />
                För att kontakta oss, besök <Link style={{ textDecoration: 'underline' }} to={Links.contact}>kontaktsidan</Link>.
                <br />
                Våra företagsuppgifter hittar du <Link style={{ textDecoration: 'underline' }} to={{ pathname: "https://www.allabolag.se/940516OIKC/sahragard-omid" }} target="_blank">här</Link>.
                {/* social media here */}
            </p>
        </PageStaticContent>
    );
}
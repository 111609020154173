import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { scrollTop, useQuery } from '../common';
import { Fader, LoadingIndicator, ImportantHeading, Heading, Spacer, DoneButton } from './BasicComponents';
import { FavoriteButton } from './FavoriteComponents';
import { withRouter } from 'react-router-dom';

// Infinite scroll documentation https://www.npmjs.com/package/react-infinite-scroll-component

export const ProductContainer = withRouter(ProductContainerComponent);
function ProductContainerComponent(props) {
    let numberOfItems = 6;

    const [items, setItems] = useState(null);
    const [itemsVisible, setItemsVisible] = useState(numberOfItems);
    const [itemsLoaded, setItemLoaded] = useState(0);
    const itemsIncrease = numberOfItems;
    const minimumItemForBackToTop = 3;

    useEffect(() => {
        if (props.items != null) {
            setItems(props.items.slice(0, itemsVisible));
        }
    }, [props.items])

    function noProductsFound() {
        function remove() {
            let params = new URLSearchParams(props.location.search);
            let keys = Array.from(params.keys());
            const lastKey = keys[keys.length - 1]
            params.delete(lastKey);
            props.history.push({ search: params.toString() });
        }

        if (props.isLoading === false) {
            let content = null;
            // Create button to remove last filter
            if (props.location.search.length > 0) {
                content = (
                    <>
                        <Spacer />
                        <DoneButton onClick={remove} buttonText="Ta bort senaste filtret" />
                    </>
                )
            }

            return (
                <div className="no-products-found">
                    <ImportantHeading>Hittade inga produkter...</ImportantHeading>
                    {content}
                </div>
            )
        }

        return '';
    }

    function loading() {
        return <LoadingIndicator>Laddar...</LoadingIndicator>;
    }

    function next() {
        let increase = itemsVisible + itemsIncrease;
        setItems(props.items.slice(0, increase));
        setItemsVisible(increase);
    }

    function end() {
        const visible = items.length > minimumItemForBackToTop ? true : false;

        return (
            <button style={visible === false ? { display: 'none' } : {}} onClick={scrollTop} className="product-container-end button">Till toppen</button>
        );
    }

    function incrementItemsLoaded() {
        setItemLoaded(itemsLoaded + 1);
    }

    if (items === null || props.isLoading === true) {
        return loading();
    } else if (items.length === 0) {
        return noProductsFound();
    } else {
        let hasMore = props.items.length > items.length;

        return (
            <>
                <Fader open={!props.isLoading && (itemsLoaded >= 6 || itemsLoaded >= items.length)}>
                    <InfiniteScroll
                        dataLength={items.length}
                        next={next}
                        hasMore={hasMore}
                        loader={loading()}
                        endMessage={end()}
                    >
                        {items.map((item, i) => (
                            <ProductWrapper markLoaded={incrementItemsLoaded} item={item} key={i}>
                                <FeedProduct />
                            </ProductWrapper>
                        ))}
                    </InfiniteScroll>
                </Fader>
            </>
        );

    }
}

export function FeedProduct({ item, brand, name, price, color, url, image, ...props }) {
    return (
        <div className={"product"}>
            <a target="_blank" href={url} rel="nofollow">
                <img onLoad={props.markLoaded} alt="product" src={image} />
            </a>

            <div className="product-like">
                <FavoriteButton item={item} />
            </div>

            <div className="product-brand special-font">{brand}</div>
            <div className="product-name">{name}</div>
            <div className="product-price">{price} <span className="product-color-wrapper"><span style={{ background: color }} className="product-color"></span></span></div>
        </div>
    );
}

export function FavoriteProduct({ item, brand, name, price, color, url, image, ...props }) {
    const baseClass = "favorite-product__";

    function ProductLink({ url, ...props }) {
        return (
            <a {...props} target="_blank" href={url} rel="nofollow">
                {props.children}
            </a>
        )
    }

    return (
        <div className={baseClass + "root"}>
            <div className={baseClass + 'img-container'}>
                <ProductLink url={url}>
                    <img onLoad={props.markLoaded} alt="product" src={image} />
                </ProductLink>
            </div>

            <div className={baseClass + 'info-container'}>
                <ProductLink className={baseClass + 'info-child-link'} url={url}>
                    <div className={baseClass + 'info-child-content'}>
                        <div className="product-brand special-font">{brand}</div>
                        <div className="product-name">{name}</div>
                        <div className="product-price">{price} <span className="product-color-wrapper"></span></div>
                    </div>
                </ProductLink>
                <div className={baseClass + 'info-like'}>
                    <FavoriteButton item={item} />
                </div>
            </div>
        </div>
    );
}

export function ProductWrapper({ ...props }) {
    function formatProductName(name) {
        if (typeof name !== 'string') return '';
        let formattedName = name.charAt(0).toUpperCase() + name.slice(1).toLocaleLowerCase();
        return formattedName;
    }

    function formatPrice(price, promotionalPrice, sale) {
        let text = <span><span className="regular-price"><span className="price-amount">{price}</span> kr</span></span>;

        if (sale === 1) {
            text = <span><span className="promotional-price"><span className="price-amount">{promotionalPrice}</span> kr</span> <span className="previous-price">{price} kr</span></span>;
        }

        return text;
    }

    function createUrl() {
        return process.env.REACT_APP_API_URL + '/exit/' + props.item.id;
    }

    function getImageUrl(item) {
        if (item.store === 'nakd') {
            return item.media_host + JSON.parse(item.media)[0] + '&width=350';
        } else if (item.store === 'zalando') {
            return item.media_host + JSON.parse(item.media)[0] + '?imwidth=350';
        } else if (item.store === 'asos') {
            return item.media_host + JSON.parse(item.media)[0] + '?$XXL$&wid=350&fit=constrain';
        } else if (item.store === 'boohoo') {
            return item.media_host + JSON.parse(item.media)[0] + '?$product_image_category_page$&fmt=webp';
        }

        return item.media_host + JSON.parse(item.media)[0];
    }

    const item = props.item;
    const url = createUrl();
    const image = getImageUrl(props.item);
    const brand = props.item.brand.toUpperCase();
    const name = formatProductName(props.item.name);
    const price = formatPrice(props.item.price, props.item.promotional_price, props.item.sale);
    const color = props.item.color;

    const child = React.cloneElement(props.children, { item, url, image, brand, name, price, color, ...props });

    return (
        child
    );
}
import React, { useEffect } from 'react';
import { useState, useContext } from 'react';
import { BsSearch, BsListNested, BsFilter } from 'react-icons/bs';
import { CategoryPage, SearchPage, FilterPage, HeaderPadding } from './PageComponents';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Fader, Overlay, OutsideClickWrapper, ScrollLock } from './BasicComponents';
import { AppContext } from '../context';
import { withRouter } from 'react-router-dom';

export const FooterButtons = withRouter(FooterButtonsComponent);
function FooterButtonsComponent({ appProps, ...props }) {
    const [value, setValue] = useState(null);
    const [overlayVisible, setOverlay] = useState(false);
    const [component, setComponent] = useState(null);
    const context = useContext(AppContext);

    const customClasses = {
        root: 'footer-buttons__button',
        selected: 'footer-buttons__selected'
    }

    function openOverlay(newValue) {
        setValue(newValue);
        setComponent(newValue);
        setOverlay(true);
        context.showHeader(true);
    }

    function closeOverlay() {
        setOverlay(false);
        context.showHeader(false);
        setValue(null); // Unmark selected button
    }

    context.close = closeOverlay;

    const components = [<CategoryPage close={closeOverlay} />, <SearchPage />, <FilterPage close={closeOverlay} appProps={appProps} />]

    return (
        <OutsideClickWrapper className="footer-buttons__wrapper" action={closeOverlay}>
            <ScrollLock lock={overlayVisible} />
            <Fader open={overlayVisible}>
                <Overlay close={closeOverlay} visible={overlayVisible}>
                    <HeaderPadding>
                        {components[component]}
                    </HeaderPadding>
                </Overlay>
            </Fader>

            <BottomNavigation classes={{ root: 'footer-buttons__container' }}
                value={value}
                onChange={(event, newValue) => {
                    if (overlayVisible === true) {
                        closeOverlay();
                        if (newValue !== value) { // Close then open next component with a delay to get the fade effect
                            setTimeout(() => {
                                openOverlay(newValue);
                            }, 150);
                        }
                    } else {
                        openOverlay(newValue);
                    }
                }}
                showLabels
            >
                <BottomNavigationAction classes={customClasses} label="Kategorier" icon={<BsListNested />} />
                <BottomNavigationAction classes={customClasses} label="Sök" icon={<BsSearch />} />
                <BottomNavigationAction classes={customClasses} label="Filter" icon={<BsFilter />} />
            </BottomNavigation>
        </OutsideClickWrapper>
    );
}
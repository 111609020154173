import { useEffect, useState } from 'react';
import { PageStaticContent } from '../components/PageComponents';
import { Endpoints, SearchConstants, setPageTitle } from '../common';
import { Links } from '../constants';
import { postRequest } from '../fetchers';
import { ImportantHeading, Spacer, Input } from "../components/BasicComponents";
import { AlphabeticList, BackToTop, BackToTopAnchor } from '../components/AdvancedComponents';

export function BrandsPage() {
    setPageTitle('Märken');
    const [brands, setBrands] = useState({}); // Dictionary
    const [filteredBrands, setFiltered] = useState({}); // Dictionary
    const [searchQuery, setSearchQuery] = useState('');

    // Get brands data
    useEffect(() => {
        postRequest(Endpoints.search, { 'query': 'brands', 'type': SearchConstants.miscData }, (result) => setBrands(result) & setFiltered(result))
    }, []);

    // Filter based on search query
    useEffect(() => {
        let newBrands = { ...brands };

        if (searchQuery.length > 0) {
            Object.keys(newBrands).map((letter) => {
                const letterLower = letter;

                newBrands[letterLower] = newBrands[letterLower].filter((item) => {
                    if (item.toLowerCase().includes(searchQuery.toLowerCase())) {
                        return true;
                    }
                    return false;
                })

            })
        }

        setFiltered(newBrands);
    }, [searchQuery])

    // Filter letters so only letters are shown that have brands
    let letters = []
    for (const [key, value] of Object.entries(filteredBrands)) {
        if (value.length > 0) {
            letters.push(key);
        }
    }

    return (
        <PageStaticContent>
            <BackToTopAnchor />
            <ImportantHeading>Märken</ImportantHeading>
            <Spacer />

            <Input onChange={e => { setSearchQuery(e.target.value) }} className="brands-page-input" placeholder="Sök märke" />
            <Spacer />

            <div className="brands-page-letters">
                {
                    letters.map((letter, i) => {
                        letter = letter.toUpperCase();
                        return <a href={Links.brands + '#' + letter} key={i}>{letter}</a>
                    })
                }
            </div>

            <AlphabeticList items={filteredBrands} />
            <BackToTop />
        </PageStaticContent>
    );
}
import React, { useState } from 'react';
import { Wrapper, Heading, Separator, Spacer, Form, Input, Textarea, DoneButton, VerticalScroller, WideWrapper, CircularButton } from './BasicComponents';
import { postRequest } from '../fetchers';
import { validateEmail, FilterEnums } from '../common';
import { Links } from '../constants';
import { Link } from 'react-router-dom';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { AiOutlineArrowUp } from 'react-icons/ai';

export function BackToTopAnchor({ ...props }) {
    return (
        <div id="back-to-top-anchor"></div>
    )
}

export function BackToTop({ ...props }) {
    const trigger = useScrollTrigger();

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <CircularButton>
                    <AiOutlineArrowUp />
                </CircularButton>

            </Box>
        </Fade>
    );
}

export function AlphabeticList({ items, ...props }) {
    const classes = {
        base: 'ac_alphabetic-list__',
        root: 'root',
        row: 'row',
        itemContainer: 'item-container',
        item: 'item',
        letter: 'letter'
    }

    function createLink(item) {
        // Fix so it links to misc page
        const link = Links.brand + encodeURIComponent(item);
        return link
    }

    function createList() {
        if (items && Object.keys(items).length > 0) {
            return (
                Object.keys(items).map((letter, i) => {
                    const letterLower = letter;
                    letter = letter.toUpperCase();
                    if (items[letterLower].length > 0) {
                        return (
                            <div id={letter} className={classes.base + classes.row} key={i}>
                                <Heading className={classes.base + classes.letter}>{letter}</Heading>
                                <Spacer />
                                <div className={classes.base + classes.itemContainer}>
                                    {items[letterLower].map((item, j) => {
                                        return <div className={classes.base + classes.item} key={j}>{<Link to={createLink(item)}>{item}</Link>}</div>
                                    })}
                                </div>
                            </div>
                        )
                    }
                })
            )
        }

        return null;
    }

    return (
        <div className={classes.base + classes.root}>
            {createList()}
        </div>
    )
}

export function ContactForm() {
    const [response, setResponse] = useState(null);
    const [disabled, setDisabled] = useState(false);

    function handleResponse(message) {
        setResponse(message ? 'Ditt meddelande har skickats!' : 'Något gick fel, försök igen.');
        setDisabled(false);
    }

    function handleSubmit(event, data) {
        if (!data['email'] || !validateEmail(data['email'])) {
            setResponse('Fyll i en giltig e-mail!');
            return;
        }

        for (const [key, value] of Object.entries(data)) {
            if (key != 'mobile' && value.length === 0) {
                setResponse('Fyll i alla fält!');
                return;
            }
        }

        setDisabled(true);
        setResponse('Skickar...');
        postRequest('/api/send_email', data, result => handleResponse(result.success))
    }

    return (
        <>
            <Form onSubmit={handleSubmit} className="components-standard-form__root">
                <Input name="email" placeholder="E-mail" />
                <Spacer />
                <Input name="mobile" placeholder="Telefon (valfritt)" />
                <Spacer />
                <Textarea name="message" rows="4" placeholder="Meddelande" />
                <Spacer />
                <DoneButton disabled={disabled} buttonText="Skicka" />
            </Form>
            <Spacer />
            <Heading centered>{response}</Heading>
        </>
    );
}

export function ExpandableMenuItemOptions() {
    const obj = {};
    obj.itemNameKey = 'itemNameKey';

    return obj;
}

export function ExpandableMenu({ items, itemOptions, ...props }) {
    const [selectedItem, setSelectedItem] = useState(itemOptions.firstIndex !== null ? itemOptions.firstIndex : null);
    const [selectedChild, setSelectedChild] = useState(itemOptions.secondIndex !== null ? itemOptions.secondIndex : null);
    const [page, setPage] = useState(itemOptions.page ? itemOptions.page : 0);

    const classes = {
        base: 'ac_expandable-menu__',
        root: 'root',
        row: 'row',
        rowSelected: 'row--selected',
        heading: 'heading',
        scroller: 'scroller',
        secondScroller: 'second-scroller'
    }

    function itemContent(item) {
        const itemContent = itemOptions.itemTemplate
            ?
            itemOptions.itemTemplate(item)
            :
            undefined; // Set to undefined if no link, regular <span> will be used instead

        const itemElement = itemContent ? itemContent : <span>{item}</span>
        return itemElement;
    }

    function childContent(item, child) {
        const itemContent = itemOptions.childTemplate
            ?
            itemOptions.childTemplate(item, child)
            :
            undefined; // Set to undefined if no link, regular <span> will be used instead

        const itemElement = itemContent ? itemContent : <span>{child}</span>
        return itemElement;
    }

    function handleNext(category) {
        setSelectedItem(category);
        setPage(1);
    }

    function handleBack() {
        setSelectedChild(null);
        setPage(0);
    }

    function headingFormat(text) {
        return (
            <div className={classes.base + classes.heading}>
                <Heading centered>
                    {text}
                </Heading>
                <Separator />
            </div>
        )
    }

    const firstHeading = itemOptions.firstHeading ? headingFormat(itemOptions.firstHeading(items[selectedItem])) : null; // Pass in the item for dynamic titles
    const secondHeading = items[selectedItem] && itemOptions.secondHeading ? headingFormat(itemOptions.secondHeading(items[selectedItem])) : null; // Pass in the item and child for dynamic titles
    const bottomButton = itemOptions.firstBottomButton ? itemOptions.firstBottomButton : null;

    const children = (
        selectedItem !== undefined && selectedItem !== null ?
            items[selectedItem].children.map((child, i) => (
                <div key={i} onClick={() => setSelectedChild(i)} className={classes.base + classes.row + (i == selectedChild ? ' ' + classes.base + classes.rowSelected : '')}>
                    {childContent(items[selectedItem], child)}
                    <Separator hidden={i === items[selectedItem].children.length - 1} />
                </div>
            ))
            :
            null
    )

    const backButton = (itemOptions.hideBack === true ? null // Remove it
        : // Keep it
        <div className="component-menu-drawer__back-button">
            <DoneButton onClick={handleBack} buttonText="Tillbaka" />
        </div>
    );

    return (
        <div className={classes.base + classes.root}>
            <Wrapper>
                {page === 0 ? // First page
                    <>
                        {firstHeading}
                        <VerticalScroller className={classes.base + classes.scroller}>
                            {items.map((item, i) => (
                                <div key={i} onClick={() => handleNext(i)} className={classes.base + classes.row + (i == selectedItem ? ' ' + classes.base + classes.rowSelected : '')}>
                                    {itemContent(item)}
                                    <Separator hidden={i === items.length - 1} />
                                </div>
                            ))}
                        </VerticalScroller>
                        <Spacer />
                        {bottomButton}
                    </>
                    : // Sub category page
                    <>
                        {secondHeading}
                        <VerticalScroller className={classes.base + classes.secondScroller}>
                            {children}
                        </VerticalScroller>
                        {backButton}
                    </>
                }
            </Wrapper>
        </div>
    )
}